<template>
  <div>
    <div
      v-for="item in marketAnalysis"
      :key="item.id"
    >
      <b-card :title="item.title">
        <b-table-simple
          bordered
          hover
        >
          <b-thead>
            <b-tr>
              <b-th>Soru</b-th>
              <b-th
                class="text-center"
                width="200"
              >
                Değerleme
              </b-th>
              <b-th
                class="text-center"
                width="200"
              >
                Notlandırma
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="question in item.questions"
              :key="question.id"
            >
              <b-td>{{ question.question }}</b-td>
              <b-td class="text-center">
                <b-badge variant="light-primary">
                  {{ question.option_text }}
                </b-badge>
              </b-td>
              <b-td class="text-center font-weight-bolder">
                {{ question.score }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-th
                class="align-middle text-primary"
                colspan="2"
              >
                Ortalama
              </b-th>
              <b-th class="text-center text-primary font-medium-4">
                {{ item.score_avg }}
              </b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BBadge, BTableSimple, BThead, BTbody, BTfoot, BTr, BTh, BTd,
} from 'bootstrap-vue'

export default {
  name: 'MarketAnalysis',
  components: {
    BCard,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    marketAnalysis() {
      return this.$store.getters['marketAnalysisReportAdmin/getMarketAnalysisList']
    },
  },
  created() {
    this.getMarketAnalysis()
  },
  methods: {
    getMarketAnalysis() {
      this.$store.dispatch('marketAnalysisReportAdmin/marketAnalysisList', this.$route.params.id)
    },
  },
}
</script>

<style scoped>

</style>
